@import 'material-icons/iconfont/material-icons.css';
/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #e01a76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

#main-image {
  width: 40vw;
}

@media (max-width: 1200px) {
  #main-image {
    width: 55vw;
  }
}
